'use client'

import {
  ReactElement,
  MouseEvent,
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
  RefAttributes,
  useRef,
} from 'react'

import classNames from 'classnames/bind'

import { Directions } from '../../constants/ui'

import styles from './SelectionGroup.scss'
import SelectionItem, { SELECTION_ITEM_CLASS } from './SelectionItem/SelectionItem'
import { getTestId } from '../../utils/testId'
import { mergeRefs } from '../../utils/mutableRefs'

export enum Styling {
  Default = 'default',
  Tight = 'tight',
  Narrow = 'narrow',
  Wide = 'wide',
}

export enum Direction {
  Vertical = Directions.Vertical,
  Horizontal = Directions.Horizontal,
}

export enum Layout {
  Default = 'default',
  Scroll = 'scroll',
}

type Props = {
  styling?: Styling | `${Styling}`
  children?: Array<ReactElement>
  direction?: Direction | `${Direction}`
  layout?: Layout | `${Layout}`
  height?: string
  forwardedRef?: ForwardedRef<HTMLDivElement>
  testId?: string
}

const cssClasses = classNames.bind(styles)

const SelectionGroup = ({
  styling = Styling.Default,
  direction = Direction.Vertical,
  layout = Layout.Scroll,
  children,
  height,
  forwardedRef,
  testId,
}: Props) => {
  const selectionGroupClasses = cssClasses('selection_group', styling, {
    [styles.horizontal]: direction === Direction.Horizontal,
    [styles.vertical]: direction === Direction.Vertical,
    [styles.vertical]: direction === Direction.Vertical,
    [styles['distribute-evenly']]: layout === Layout.Default,
    [styles.scroll]: layout === Layout.Scroll,
  })

  const containerRef = useRef<HTMLDivElement>(null)

  const handleClick =
    (callback?: (event: MouseEvent<HTMLDivElement>) => void) =>
    async (event: MouseEvent<HTMLDivElement>) => {
      if (callback) {
        callback(event)
      }

      if (layout !== Layout.Scroll) return

      const clickedElement = event.target instanceof HTMLElement ? event.target : null
      if (!clickedElement || !containerRef.current) return

      const closestElement = clickedElement.closest(`.${SELECTION_ITEM_CLASS}`)

      const isVisible = await new Promise<boolean>(resolve => {
        const observer = new IntersectionObserver(
          ([entry]) => {
            resolve(entry.isIntersecting)
            observer.disconnect()
          },
          { threshold: [0.99] },
        )
        observer.observe(closestElement || clickedElement)
      })

      if (!isVisible && closestElement) {
        const containerRect = containerRef.current.getBoundingClientRect()
        const itemRect = closestElement.getBoundingClientRect()

        const scrollOptions: ScrollToOptions = {
          behavior: 'smooth',
        }

        if (direction === Direction.Vertical) {
          containerRef.current.scrollTo({
            top:
              itemRect.top -
              containerRect.top +
              containerRef.current.scrollTop -
              containerRect.height / 2 +
              itemRect.height / 2,
            ...scrollOptions,
          })
        } else if (direction === Direction.Horizontal) {
          containerRef.current.scrollTo({
            left:
              itemRect.left -
              containerRect.left +
              containerRef.current.scrollLeft -
              containerRect.width / 2 +
              itemRect.width / 2,
            ...scrollOptions,
          })
        }
      }
    }

  const renderItems = () => {
    if (!children) return null

    return (
      <div
        className={selectionGroupClasses}
        {...(layout !== Layout.Default && { style: { height, minHeight: height } })}
        data-testid={getTestId(testId, 'main')}
        ref={mergeRefs(forwardedRef, containerRef)}
      >
        {children.map((child, index) => (
          <SelectionItem
            key={index}
            {...child.props}
            className={
              child.props.className ? [direction, child.props.className].join('') : direction
            }
            onClick={handleClick(child.props.onClick)}
          />
        ))}
      </div>
    )
  }

  return renderItems()
}

interface SelectionGroupPropsWithRef
  extends ForwardRefExoticComponent<Props & RefAttributes<HTMLDivElement>> {
  Direction: typeof Direction
  Layout: typeof Layout
  Styling: typeof Styling
  SelectionItem: typeof SelectionItem
}

export const SelectionGroupWithForwardRef = forwardRef<HTMLDivElement, Props>((props, ref) => (
  <SelectionGroup forwardedRef={ref} {...props} />
)) as SelectionGroupPropsWithRef

SelectionGroupWithForwardRef.displayName = SelectionGroup.name
SelectionGroupWithForwardRef.SelectionItem = SelectionItem
SelectionGroupWithForwardRef.Direction = Direction
SelectionGroupWithForwardRef.Layout = Layout
SelectionGroupWithForwardRef.Styling = Styling

export default SelectionGroupWithForwardRef
