'use client'

import { ReactNode } from 'react'

import Tooltip from '../Tooltip/Tooltip'
import styles from './RangeGraph.scss'

enum Position {
  High = 'high',
  Low = 'low',
  Typical = 'typical',
}

type Props = {
  lower: ReactNode
  upper: ReactNode
  showLabel: boolean
  label: ReactNode
  position: Position | `${Position}`
}

const positionToTooltipPlacement = {
  [Position.Low]: Tooltip.Placement.TopLeft,
  [Position.High]: Tooltip.Placement.TopRight,
  [Position.Typical]: Tooltip.Placement.Top,
}

const RangeGraph = ({ lower, upper, label, position, showLabel }: Props) => (
  <div className={styles.container}>
    <Tooltip
      placement={positionToTooltipPlacement[position]}
      hover={false}
      show={showLabel}
      content={<div className="u-text-left"> {label}</div>}
    >
      <div className={styles.slider}>
        <span className={styles.bar} />
        <span className={styles.bar} />
        <span className={styles.bar} />
      </div>
      <div className={styles.label}>
        {lower}
        {upper}
      </div>
    </Tooltip>
  </div>
)

RangeGraph.Position = Position

export default RangeGraph
