import * as React from 'react';

const HorizontalDots16 = ({ title, titleId, ...props }) => /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 16 16", width: 16, height: 16, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("g", { fill: "none", fillRule: "evenodd" }, /* @__PURE__ */ React.createElement("path", { d: "M0 0h16v16H0z" }), /* @__PURE__ */ React.createElement(
  "path",
  {
    fill: "currentColor",
    d: "M15 8.5a1.5 1.5 0 1 1-3.001-.001A1.5 1.5 0 0 1 15 8.5zm-11 0a1.5 1.5 0 1 1-3.001-.001A1.5 1.5 0 0 1 4 8.5zm5.5 0a1.5 1.5 0 1 1-3.001-.001A1.5 1.5 0 0 1 9.5 8.5z"
  }
)));
HorizontalDots16.Size = 16;
HorizontalDots16.Title = "HorizontalDots";

export { HorizontalDots16 as default };
