import * as React from 'react';

const ChevronUp24 = ({ title, titleId, ...props }) => /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 24 24", width: 24, height: 24, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("g", { fill: "none", fillRule: "evenodd" }, /* @__PURE__ */ React.createElement("path", { d: "M0 0h24v24H0z" }), /* @__PURE__ */ React.createElement(
  "path",
  {
    fill: "currentColor",
    d: "m18.002 14.45-6.001-5.949L6 14.451l1.06 1.05 4.941-4.897 4.94 4.897z"
  }
)));
ChevronUp24.Size = 24;
ChevronUp24.Title = "ChevronUp";

export { ChevronUp24 as default };
