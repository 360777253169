'use client'

import { useEffect, useState } from 'react'
import classNames from 'classnames/bind'

import { ChevronDown24, ChevronUp24 } from '@vinted/monochrome-icons'

import Cell from '../Cell'
import Icon from '../Icon'

import { randomId } from '../../utils/randomId'
import { getTestId } from '../../utils/testId'

import styles from './Accordion.scss'
import { noop } from '../../utils/noop'

enum Styling {
  Regular = 'regular',
  Narrow = 'narrow',
  Wide = 'wide',
  TightExperimental = 'tight-experimental',
}

type CellStylingType = (typeof Cell.Styling)[keyof typeof Cell.Styling]

const ACCORDION_CELL_STYLE_MAP: Record<Styling, CellStylingType | undefined> = {
  [Styling.Regular]: undefined,
  [Styling.Narrow]: Cell.Styling.Narrow,
  [Styling.Wide]: Cell.Styling.Wide,
  [Styling.TightExperimental]: Cell.Styling.Tight,
}

type Props = {
  /**
   * Styling that gets passed to `Cell` components wrapping title and content.
   */
  styling?: Styling | `${Styling}`
  /**
   * Title of the component that is visible when the accordion is closed.
   * Additional content is revealed when the title is clicked.
   */
  title: React.ReactNode | string
  /**
   * Sets suffix that is displayed when the component is not expanded.
   */
  content: React.ReactNode | string
  /**
   * Sets suffix that is displayed when the component is expanded.
   */
  closedSuffix?: JSX.Element | string | null
  /**
   * Sets prefix that is displayed when the component is not expanded.
   */
  expandedSuffix?: JSX.Element | string | null
  /**
   * Sets prefix that is displayed when the component is expanded.
   */
  closedPrefix?: JSX.Element | string | null
  /**
   * Sets prefix that is displayed when the component is expanded.
   */
  expandedPrefix?: JSX.Element | string | null
  /**
   * Controls the expanded/closed state.
   */
  isExpanded?: boolean
  headerId?: string
  bodyId?: string
  onToggle?: (isExpanded: boolean) => void
  /**
   * Experimental property which inverts colors.
   */
  inverseExperimental?: boolean
  /**
   * Adds data-testid attribute to children components.
   * When used, --header and --content suffixes are applied accordingly
   */
  testId?: string
}

const cssClasses = classNames.bind(styles)

const Accordion = ({
  styling = Styling.Regular,
  title,
  content,
  closedSuffix = (
    <Icon name={ChevronDown24} color={Icon.Color.GreyscaleLevel2} display={Icon.Display.Block} />
  ),
  expandedSuffix = (
    <Icon name={ChevronUp24} color={Icon.Color.GreyscaleLevel2} display={Icon.Display.Block} />
  ),
  closedPrefix,
  expandedPrefix,
  isExpanded: propIsExpanded = false,
  headerId: initialHeaderId = `accordion-header${randomId()}`,
  bodyId: initialBodyId = `accordion-body${randomId()}`,
  onToggle = noop,
  inverseExperimental,
  testId,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(propIsExpanded)

  useEffect(() => {
    setIsExpanded(propIsExpanded)
  }, [propIsExpanded])

  const handleTitleClick = () => {
    setIsExpanded(prevIsExpanded => {
      const newIsExpanded = !prevIsExpanded
      onToggle(newIsExpanded)

      return newIsExpanded
    })
  }

  const renderHeader = () => {
    const suffix = isExpanded ? expandedSuffix : closedSuffix
    const prefix = isExpanded ? expandedPrefix : closedPrefix

    return (
      <Cell
        id={initialHeaderId}
        theme={inverseExperimental ? 'inverseExperimental' : undefined}
        styling={ACCORDION_CELL_STYLE_MAP[styling]}
        suffix={suffix}
        prefix={prefix}
        type={Cell.Type.Navigating}
        title={title}
        onClick={handleTitleClick}
        aria={{
          'aria-expanded': isExpanded,
          'aria-controls': initialBodyId,
        }}
        testId={getTestId(testId, 'header')}
      />
    )
  }

  const renderBody = () => {
    const bodyClass = cssClasses(styles.body, { expanded: isExpanded })
    const contentClass = cssClasses(styling)

    return (
      <div
        hidden={!isExpanded}
        aria-hidden={!isExpanded}
        className={bodyClass}
        data-testid={getTestId(testId, 'body')}
      >
        <Cell
          id={initialBodyId}
          styling={Cell.Styling.Tight}
          theme={inverseExperimental ? 'inverseExperimental' : undefined}
          body={<div className={contentClass}>{content}</div>}
          role="region"
          aria={{
            'aria-labelledby': initialHeaderId,
          }}
          testId={getTestId(testId, 'content')}
        />
      </div>
    )
  }

  return (
    <div data-testid={testId}>
      {renderHeader()}
      {renderBody()}
    </div>
  )
}

Accordion.Styling = Styling

export default Accordion
