'use client'

import { ReactNode, useEffect, useState, useMemo } from 'react'

import ThemeContext, { ColorMode } from './ThemeContext'

type Props = {
  children: ReactNode
  mode: ColorMode | `${ColorMode}`
}

const ThemeProvider = ({ mode: defaultMode, children }: Props) => {
  const [mode, setMode] = useState(defaultMode)

  useEffect(() => {
    setMode(defaultMode)
  }, [defaultMode])

  const value = useMemo(() => ({ mode, updateMode: setMode }), [mode])

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
}

export default ThemeProvider
