import * as React from 'react';

const ChevronDown24 = ({ title, titleId, ...props }) => /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 24 24", width: 24, height: 24, "aria-labelledby": titleId, ...props }, title ? /* @__PURE__ */ React.createElement("title", { id: titleId }, title) : null, /* @__PURE__ */ React.createElement("g", { fill: "none", fillRule: "evenodd" }, /* @__PURE__ */ React.createElement("path", { d: "M0 0h24v24H0z" }), /* @__PURE__ */ React.createElement(
  "path",
  {
    fill: "currentColor",
    d: "m18.002 9.552-1.061-1.051-4.94 4.897L7.06 8.501 6 9.552l6.001 5.949z"
  }
)));
ChevronDown24.Size = 24;
ChevronDown24.Title = "ChevronDown";

export { ChevronDown24 as default };
